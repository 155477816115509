import { useState } from "react";
import { STATUS_CODE } from "../../config/constants";
import { showError } from "../../utils/message";

export function useFetchList() {
  const [list, setList] = useState([]);
  const load = (service, callback = () => {}, args) =>
    service(args)
      .then(({ data }) => {
        if (data.statusCode !== STATUS_CODE.SUCCESS) {
          Promise.reject(data.message);
        }
        setList(data.data);
        callback(data.data);
      })
      .catch((err) => {
        setList([]);
      });
  return { load, list };
}

export function useFetchPost() {
  const execute = (service, callback = () => {}, args = null) =>
    service(args)
      .then(({ data }) => {
        if (data.statusCode !== STATUS_CODE.SUCCESS) {
          showError(data.message);
          Promise.reject(data.message);
          return;
        }
        callback(data);
      })
      .catch((err) => {
        Promise.reject(err.message);
      });
  return execute;
}

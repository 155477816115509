import React, { useEffect } from "react";
import { List as ListCrud, useCrudList } from "../Crud";
import config from "../../config/crud/sessionStations";

export const SessionByStationsList = ({ filterValues, initialLoad = false }) => {
  const { dataSource, columns, loading, reload } = useCrudList({
    initialLoad,
    ...config,
  });

  useEffect(() => {
    if (filterValues) {
      reload(filterValues);
    }
  }, [filterValues]);

  return (
    <ListCrud
      subTitle={"Consolidado de conexiones por estación"}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={{
        pageZise: 20,
        showQuickJumper: true,
        showSizeChanger: true,
      }}
    />
  );
};

export default SessionByStationsList;

import { FILTER_TYPE } from "../../config/constants";

const config = {
  keyName: "id",
  getList: `/station/sessionsSummary`,
  getByKey: "/station/sessionsSummary",
  message: "message",
  fields: [
    {
      title: "Estación",
      key: "stationId",
      textKey: "stationName",
      sorter: true,
      filter: true,
      filterType: FILTER_TYPE.options,
      type: "select",
      configOptions: {
        url: "options/stations",
        map: (item) => ({ [item.value]: item.text }),
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Cantidad de sesiones",
      key: "sessionsCount",
      textKey: "sessionsCount",
      sorter: true,
      filter: true,
      render: (text) => `${text} sesiones`,
    },
    {
      title: "Tiempo de conexión",
      key: "timeSum",
      textKey: "timeSum",
      sorter: true,
      filter: true,
      render: (text) => `${text} horas`,
    },
  ],
};

export default config;

import instance from "./instance";

const url = {
  get: "station",
  maintenance: "station/setOnMaintenance/",
  showOnApp: "station/showOnApp/",
  find: 'station/find',
};

export const getAllService = () => instance.get(url.get);

export const findService = (data) => instance.get(url.find, {
  params: data
});

export const setOnMaintenanceService = ({ stationId, enabled }) =>
  instance.put(`${url.maintenance}${stationId}`, {
    enabled,
  });

export const showOnAppService = ({ stationId, show }) =>
  instance.put(`${url.showOnApp}${stationId}`, {
    show,
  });

import React from "react";
import { useTheme } from "./hooks";
import { Switch } from "antd";
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";

const SwitchChangeTheme = ({}) => {
  const [darkMode, setDarkMode] = useTheme();
  return (
    <Switch
      checked={darkMode}
      onChange={setDarkMode}
      checkedChildren={<BsFillMoonFill />}
      unCheckedChildren={<BsFillSunFill />}
    />
  );
};

export default SwitchChangeTheme;

import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { Layout, Row, Col, Card, Button } from "antd";

import { Title } from "../../components/Shared";
import { Outlet } from "react-router-dom";
import { VERSION } from "../../config/constants";
import "./login.css";

const { Footer, Content } = Layout;

const Login = (props) => {
  // const { isAuthenticated } = useAuthenticated();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate(ROUTES.home);
  //   }
  // }, []);

  const CardTitle = (
    <Title level={3} className="layout-title" text={"Sign in your account"} />
  );

  return (
    <Layout
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%)",
        backgroundSize: "cover",
      }}
    >
      <Content>
        <Row
          justify="center"
          align="middle"
          style={{
            height: "91vh",
          }}
        >
          <span className="oasis-background-1"></span>
          <span className="oasis-background-2"></span>
          <Col xs={22} md={8} xl={8}>
            <Row justify="start" align="top">
              <Col xs={22} md={8} xl={8}></Col>
            </Row>
            <Card
              cover={
                <div className="oasis-image-cover oasis-image-grid">
                  <img
                    className="oasis-item-d oasis-image-logo"
                    src="https://oasisgroup.online/wp-content/uploads/2020/02/Recurso-4@4x.png"
                  />
                </div>
              }
              hoverable
              headStyle={{ borderBottom: 0 }}
            >
              <Outlet />
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer
        style={{ textAlign: "center", background: "#001529", color: "white" }}
      >
        Oasis back office © 2021
        <br />
        {VERSION}
      </Footer>
    </Layout>
  );
};

export default Login;

import { FILTER_TYPE } from "../constants";
import {
  dateToString,
  validatePassword,
  validateLength,
} from "../../utils/general";

const config = {
  keyName: "userId",
  getList: "/administration/users",
  getByKey: "/administration/users",
  post: "/administration/users",
  put: "/administration/users",
  delete: "/administration/users",
  message: "message",
  fields: [
    {
      title: "Email Confirmado",
      key: "emailConfirmed",
      textKey: "emailConfirmed",
      sorter: true,
      filter: true,
      type: "bool",
      features: {
        checkedChildren: "Sí",
        unCheckedChildren: "No",
      },
      hidden: ["form"],
      render: (text, record) => (text == "1" ? `Sí` : "No"),
    },
    {
      title: "Acepta Términos",
      key: "allowPersonalDataStore",
      textKey: "allowPersonalDataStore",
      sorter: true,
      filter: true,
      type: "bool",
      features: {
        checkedChildren: "Sí",
        unCheckedChildren: "No",
      },
      hidden: ["form"],
      render: (text, record) => (text == "1" ? `Sí` : "No"),
    },
    {
      title: "Nombres",
      key: "name",
      textKey: "name",
      sorter: true,
      filter: true,
      type: "string",
      col: 12,
      rules: [{ required: true, message: "Este campo es obligatorio." }],
      render: (text, record) => `${text} ${record.lastName}`,
    },
    {
      title: "Apellidos",
      key: "lastName",
      textKey: "lastName",
      sorter: true,
      filter: true,
      type: "string",
      col: 12,
      hidden: ["column"],
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Tipo de Documento",
      key: "documentTypeId",
      textKey: "documentTypeName",
      sorter: true,
      filter: true,
      type: "select",
      col: 12,
      hidden: ["column"],
      configOptions: {
        url: "options/documentTypes",
        map: (item) => ({ [item.value]: item.text }),
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Número de documento",
      key: "documentNumber",
      textKey: "documentNumber",
      sorter: true,
      filter: true,
      type: "number",
      col: 12,
      rules: [{ required: true, message: "Este campo es obligatorio." }],
      features: {
        style: { width: "100%" },
      },
      render: (text, record) => `${record.documentTypeName}: ${text}`,
    },
    {
      title: "Teléfono",
      key: "phone",
      textKey: "phone",
      sorter: true,
      filter: true,
      col: 8,
      type: "number",
      features: {
        style: { width: "100%" },
      },
      rules: [
        {
          validator: (_, value) => validateLength(value, 7),
        },
      ],
      render: (text, record) =>
        `${record.phone ? `${text} - ` : ""} ${record.countryCodeName} ${
          record.cellPhone
        }`,
    },
    {
      title: "Indicativo de País",
      key: "countryCodeId",
      textKey: "countryCodeName",
      sorter: true,
      filter: true,
      type: "select",
      col: 3,
      configOptions: {
        url: "options/countryCodes",
        map: (item) => ({ [item.value]: item.text }),
      },
      hidden: ["column"],
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Número de teléfono móvil",
      key: "cellPhone",
      textKey: "cellPhone",
      sorter: true,
      filter: true,
      type: "number",
      features: {
        style: {
          width: "100%",
        },
      },
      col: 5,
      hidden: ["column"],
      rules: [
        { required: true, message: "Este campo es obligatorio." },
        {
          validator: (_, value) => validateLength(value, 10),
        },
      ],
    },
    {
      title: "Ciudad",
      key: "cityId",
      textKey: "cityName",
      sorter: true,
      filter: true,
      type: "select",
      col: 8,
      configOptions: {
        url: "options/cities",
        map: (item) => ({ [item.id]: item.label }),
      },
      rules: [],
    },
    {
      title: "Dirección",
      key: "address",
      textKey: "address",
      sorter: true,
      filter: true,
      type: "textarea",
      features: {
        showCount: true,
        maxLength: 200,
      },
    },
    {
      title: "Correo",
      key: "email",
      textKey: "email",
      sorter: true,
      filter: true,
      type: "string",
      rules: [
        {
          type: "email",
          message: "¡Debe de ingresar un correo electronico valido!",
        },
        { required: true, message: "Este campo es obligatorio." },
      ],
    },
    {
      title: "Fecha de creación",
      key: "created_at",
      textKey: "created_at",
      sorter: true,
      filter: true,
      type: "string",
      hidden: ["form"],
      render: (text, record) => dateToString(record.created_at),
    },
    {
      title: "Tipo de Suscripción",
      key: "suscriptionTypeId",
      textKey: "suscriptionTypeName",
      sorter: true,
      filter: true,
      type: "select",
      col: 12,
      configOptions: {
        url: "options/getByName/SUSCRIPTION_TYPES",
        map: (item) => ({ [item.value]: item.text }),
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
      render: (text, record) =>
        record.suscriptionTypeName == "" || record.suscriptionTypeName == null
          ? "Sin indicar"
          : record.suscriptionTypeName,
    },
    {
      title: "Método de Contacto",
      key: "contactMethod",
      textKey: "contactMethodName",
      sorter: true,
      filter: true,
      type: "select",
      col: 12,
      configOptions: {
        url: "options/getByName/CONTACT_METHOD",
        map: (item) => ({ [item.value]: item.text }),
      },
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Datos de autenticación",
      key: "object",
      type: "divider",
      level: 1,
      hidden: ["column"],
    },
    {
      title: "Nombre de Usuario",
      key: "username",
      textKey: "username",
      sorter: true,
      filter: true,
      type: "string",
      disabledWhenEditingForm: true,
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Rol",
      key: "roleId",
      textKey: "roleName",
      sorter: true,
      filter: true,
      filterType: FILTER_TYPE.options,
      type: "select",
      configOptions: {
        url: "options/roles",
        map: (item) => ({ [item.value]: item.text }),
      },
      disabledWhenEditingForm: (item) => item.roleId == "9",
      rules: [{ required: true, message: "Este campo es obligatorio." }],
    },
    {
      title: "Contraseña",
      key: "password",
      textKey: "password",
      sorter: true,
      filter: true,
      type: "password",
      hidden: ["column"],
      hideWhenEditingForm: true,
      formFeatures: {
        hasFeedback: true,
      },
      rules: [
        { required: true, message: "Este campo es obligatorio." },
        {
          validator: (_, value) => {
            const result = validatePassword(value);
            if (result.trim().length === 0) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(result));
          },
        },
      ],
    },
    {
      title: "Confirme su contraseña",
      key: "confirmPassword",
      textKey: "confirmPassword",
      sorter: true,
      filter: true,
      type: "password",
      hideWhenEditingForm: true,
      features: {
        dependencies: ["password"],
      },
      formFeatures: {
        hasFeedback: true,
      },
      hidden: ["column"],
      rules: [
        { required: true, message: "Este campo es obligatorio." },
        ({ getFieldValue }) => ({
          validator: (_, value) => {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("¡La dos contraseñas no coinciden!")
            );
          },
        }),
      ],
    },
  ],
};

export default config;

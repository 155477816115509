import React from "react";
import * as Icons from "react-icons/fa";

const Icon = ({ type = "FaEthereum", size, color }) => {
  return (
    <span
      style={{ fontSize: size, color: color }}
      role="img"
      aria-label="ci-circle"
      className="anticon anticon-ci-circle"
    >
      {React.createElement(Icons[type])}
    </span>
  );
};

export default Icon;
import React, { useState, useEffect } from "react";
import store from "../../config/store";

export function useAuthenticated() {
  const [auth, setAuth] = useState(store.getState().auth);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const newAuth = store.getState().auth;
      if (auth !== newAuth) {
        setAuth(newAuth);
      }
    });
    return unsubscribe;
  }, [auth]);
  return { ...(auth || {}) };
}

export function useAuthorization(screen) {
  const [permissions, setPermissionsList] = useState();

  useEffect(() => {
    const allPermissions = store.getState().auth?.permissions;
    setPermissionsList(allPermissions[screen]);
  }, [screen]);

  const validatePermission = (action) => {
    if (permissions) {
      return permissions.includes(action);
    }
    return true;
  };

  return { permissions, validatePermission };
}

const defaultState = {
  transactions: {},
};

const charger = {
  state: defaultState,
  reducers: {
    SET_TRANSACTION: (transactions, payload) => ({
      transactions,
      ...payload,
    }),
  },
  effects: {
    endTransaction({ chargerId }, store) {
      const transactions = store.charger.transactions;
      if (transactions[chargerId]) {
        delete transactions[chargerId];
        this.SET_TRANSACTION(transactions);
      }
    },
    startTransaction({ transaction }, store) {
      const chargerId = transaction.chargerId;
      if (chargerId == null) {
        return false;
      }
      const transactions = {
        [chargerId]: transaction.transactionId,
        ...store.charger.transactions,
      };
      this.SET_TRANSACTION(transactions);
    },
  },
};

export default charger;

import menuDataSet from "../config/menus";
// import { list } from '../services/security/menu';

const config = {
  state: {
    menu: [],
  },
  reducers: {
    SET_MENU: (state, payload) => {
      return {
        ...state,
        menu: payload,
      };
    },
  },
  effects: {
    loadMenu() {
      this.SET_MENU(menuDataSet);
    },
    removeMenu() {
      //   const promise = await list();
      //   const menu = await promise.data;
      this.SET_MENU([]);
    },
  },
};

export default config;

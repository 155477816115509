import Instance from "./instance";

const URLs = {
  generateQr: "charger/generateQr",
  relays: "options/getByName/CHARGER_RELAYS",
  types: "options/chargerType",
  report: "userChargerLog/download",
  enableCharger: "charger/enableCharger",
  monitoring: "chargerStatus/monitoring",
  disableCharge: "charger/disable",
  activeChargers: "charger/activeChargers",
};

export const getActiveChargersService = () => Instance.get(URLs.activeChargers);

export const changeChargerStateService = ({ id, state }) =>
  Instance.put(URLs.disableCharge, { id, state });

export const getMonitoringChargerService = (transactionId) =>
  Instance.get(URLs.monitoring, {
    params: { transactionId },
  });

export const generateQrService = (charger) =>
  Instance.post(URLs.generateQr, charger);

export const getRelaysService = () => Instance.get(URLs.relays);

export const getChargerTypesService = () => Instance.get(URLs.types);

export const enableChargerService = (chargerId) =>
  Instance.put(URLs.enableCharger, { chargerId });

export const downloadUserReport = () =>
  Instance({
    url: URLs.report, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

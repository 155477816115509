import React from 'react';
import {
    Button,
    Form,
    Input,
    Checkbox,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config/constants';

const Login = (props) => {
    const { loading } = props;
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={(values) => {
                props.login(values)
            }}
        >
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Por favor ingrese su nombre de usuario!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: '¡Por favor  ingrese su contraseña!' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <Link to={ROUTES.forgotPassword} className="login-form-forgot">Recordar Contraseña</Link>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="oasis-button-primary"
                    loading={loading}
                    block
                >
                    Ingresar
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Login;



import React, { useEffect, useState } from "react";
import { Row, Col, Card, Statistic, Badge, Table } from "antd";
import { UserSwitchOutlined, ApiOutlined } from "@ant-design/icons";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import PropTypes from "prop-types";

import { groupBy, getMaxNumber } from "../../utils/general";
import { Title, useFetchList } from "../Shared";
import { usersByCreatedAtService } from "../../services/administration";
import moment from "moment";
import { dateTimeFormat } from "../../config/constants";
import { SessionSummaryByStationList } from "../Report";

const HomeCard = ({ dataSource, loading, filters }) => {
  const [usersByStation, setUsers] = useState([]),
    [soportes, setSoportes] = useState(0),
    [userMaxCharge, setMax] = useState({});

  const userByCreatedAtServiceAPI = useFetchList();

  useEffect(() => {
    if (filters) {
      events.getNewUsers(filters);
    }
  }, [filters]);

  const events = {
    prepareData: (data) => {
      const stations = groupBy(data, "stationName");
      setUsers(
        Object.keys(stations).map((key) => ({
          key,
          name: key,
          count: stations[key].length,
        }))
      );
      setSoportes(data[0].soportes);
      setMax(getMaxNumber(data, "sesiones"));
    },
    getNewUsers: (data) =>
      userByCreatedAtServiceAPI.load(usersByCreatedAtService, () => {}, data),
  };

  useEffect(() => {
    dataSource.length > 0 && events.prepareData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    events.getNewUsers({
      dateFrom: moment().subtract(5, "d").format(dateTimeFormat),
      dateTo: moment().add(1, "d").format(dateTimeFormat),
    });
  }, []);

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card hoverable>
              <Statistic
                loading={loading}
                title="Usuarios nuevos en los últimos 5 días"
                value={userByCreatedAtServiceAPI.list?.length}
                prefix={<AiOutlineUsergroupAdd />}
                suffix={<Title level={5} text="Usuarios" />}
              />
            </Card>
          </Col>
          <Col span={24}>
            <Card hoverable>
              <Statistic
                loading={loading}
                title={userMaxCharge.fullname}
                value={userMaxCharge.sesiones}
                prefix={<ApiOutlined />}
                suffix={<Title level={5} text={"sesiones"} />}
              />
            </Card>
          </Col>
          <Col span={24}>
            <Card hoverable>
              <Statistic
                loading={loading}
                title="Cantidad de soportes"
                value={soportes}
                prefix={<UserSwitchOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <Card hoverable>
          <SessionSummaryByStationList
            filterValues={filters}
            initialLoad={true}
          />
        </Card>
      </Col>
    </Row>
  );
};

HomeCard.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  title: PropTypes.node,
};

export default HomeCard;

import { Form, Input } from "antd";
import React from "react";

import { Modal, useFetchPost } from "../Shared";
import { changePassword as service } from "../../services/auth";
import { showSuccess } from "../../utils/message";
import { validatePassword } from "../../utils/general";

const ChangePassword = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const fetchPost = useFetchPost();

  const events = {
    confirm: () => {
      fetchPost(
        service,
        () => {
          showSuccess("Contraseña cambiada con exito!");
          events.cancel();
        },
        form.getFieldValue()
      );
    },
    cancel: () => {
      onCancel();
    },
  };

  return (
    <Modal
      title="Cambiar Contraseña"
      visible={visible}
      onOk={events.confirm}
      onCancel={events.cancel}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="Contraseña actual"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              validator: (_, value) => {
                const result = validatePassword(value);
                if (result.trim().length === 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(result));
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nueva contraseña"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              validator: (_, value) => {
                const result = validatePassword(value);
                if (result.trim().length === 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(result));
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirmar contraseña"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePassword;

import React, { useState, forwardRef } from "react";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { showError } from "../../utils/message";
import { uploadService, removeService } from "../../services/helper";
import { API_URL } from "../../config/constants";
import instance from "../../services/instance";
import { useEffect } from "react";


const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    showError("¡Solo se admiten imagenes .png o .jpeg!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    showError("La imagen debe de ser menor a 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const UploadComponent = forwardRef(
  ({ action, name, onChange, initialImage, ...props }, ref) => {
    const [loading, setLoading] = useState(false);
    const [imgUrl, setImage] = useState(null);

    useEffect(() => {
      if (initialImage) {
        setImage(initialImage);
      }
    }, [initialImage]);

    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }

      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
          setLoading(false);
          setImage(url);
        });
      }
      onChange && onChange(info?.file?.response?.data);
    };

    const onRemove = (file) => {
      const { remove } = props;
      const _file =
        file.response &&
        file.response.attachments.find((e) => e.name == file.name);
      (remove && !file.response
        ? instance.delete(`${remove}/${file.uid}`)
        : removeService(_file.url.replace(process.env.REACT_APP_BASE, ""))
      ).then((e) => console.log("File Removed", e.data));
    };

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        ref={ref}
        name={name || "file"}
        action={action || `${API_URL}helper/upload`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        defaultFileList={props.value}
        onRemove={onRemove}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        {...props}
      >
        {imgUrl ? (
          <img
            src={imgUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
);

UploadComponent.propTypes = {
  action: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default UploadComponent;

import React from 'react';
import { Button, Popconfirm } from "antd";
import PropTypes from "prop-types";

const DeleteButton = (props) => {
  return (
    <Popconfirm
      disabled={props.disabled}
      title={props.confirm}
      placement="bottomRight"
      onConfirm={props.onConfirm}
    >
      <Button
        type={props.type || "ghost"}
        shape="circle"
        icon={props.icon}
        disabled={props.disabled}
      />
    </Popconfirm>
  );
};

DeleteButton.propType = {
  disabled: PropTypes.bool,
  confirm: PropTypes.string,
  onConfirm: PropTypes.func,
  ghost: PropTypes.bool,
  danger: PropTypes.bool,
  shape: PropTypes.oneOf(["default", "circle", "round"]),
  type: PropTypes.oneOf(["primary", "ghost", "dashed", "text", "link"]),
};

export default DeleteButton;

import React, { useState, useEffect } from "react";
import { useAuthenticated } from "../Auth";
import { GUEST, LOGGED } from "../../config/constants";
import store from "../../config/store";

export function useMenu(position) {
  let permissions = store.getState().auth.permissions;

  const hideMenues = (_menu) => {
    if (_menu == null) {
      return {};
    }
    if (permissions == null) {
      permissions = {};
    }
    _menu.forEach((menuItem) => {
      if (menuItem.hasOwnProperty("subMenus")) {
        hideMenues(menuItem.subMenus);
        menuItem.show = menuItem.subMenus.filter((e) => e.show).length > 0;
        return;
      }
      menuItem.show = permissions.hasOwnProperty(menuItem.path);
    });
  };

  const fillMenu = (_menu) => {
    if (_menu == null) {
      return [];
    }
    hideMenues(_menu);
    return _menu;
  };

  const { isAuthenticated } = useAuthenticated();
  const [menus, setMenu] = useState(
    fillMenu(store.getState().config.menu[position])
  );

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const _menu = store.getState().config.menu[position];
      if (_menu && menus !== _menu) {
        setMenu(fillMenu(_menu));
      }
    });
    return unsubscribe;
  }, [isAuthenticated, menus, position]);

  return menus || [];
}

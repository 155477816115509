import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ModalGeneric = props => (
  <Modal centered maskClosable={false} {...props}>
    {!props.visible ? '' : props.children }
  </Modal>
);

ModalGeneric.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  children: PropTypes.object.isRequired,
  style: PropTypes.object,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  confirmLoading: PropTypes.bool,
  footer: PropTypes.object,
  width: PropTypes.number,
};

export default ModalGeneric;
import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = ({ value, onChange, ...props }) => {
  const [displayColorPicker, showComponent] = useState(false);
  const [color, setColor] = useState({});

  useEffect(() => {
    if (value) {
      setColor(value);
    }
  }, [value]);

  const events = {
    handleClick: () => {
      showComponent(!displayColorPicker);
    },
    handleClose: () => {
      showComponent(false);
    },
    handleChange: (color) => {
      setColor(color.hex);
      onChange(color.hex);
    },
  };

  const styles = {
    color: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
      background: `${color}`,
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div>
      <div style={styles.swatch} onClick={events.handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={events.handleClose} />
          <SketchPicker
            onChangeComplete={events.handleChange}
            color={color}
            {...props}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;

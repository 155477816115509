import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Form as FormCrud, useCrudForm } from "../../components/Crud";
import conf from "../../config/crud/user";
import { ROUTES, TYPE_MESSAGE } from "../../config/constants";
import { show } from "../../utils/message";

const Form = ({ userData }) => {
  const { id = null } = useParams(),
    navigate = useNavigate();
  const propsForm = useCrudForm({ conf, key: id });

  const events = {
    submit: (values) => {
      if (values.password !== values.confirmPassword) {
        return show({
          type: TYPE_MESSAGE.Warning,
          message: "Las contraseñas no coinciden",
        });
      }
      propsForm.onSubmit(
        {
          ...userData,
          ...values, 
        },
        () => navigate(ROUTES.users)
      );
    },
  };

  if (id > 0 && !propsForm.fields[1].hasOwnProperty("value")) {
    return "Loading...";
  }

  return (
    <FormCrud
      title="Usuario"
      rowKey={conf.keyName}
      {...propsForm}
      layout="vertical"
      verticalLabel
      onSubmit={events.submit}
    />
  );
};

Form.propTypes = {
  match: PropTypes.object,
};

export default Form;

import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import { useMenu } from "./hooks";
import { DynamicIcon as Icon } from "../Shared";
import { HomeFilled } from "@ant-design/icons";

const SubMenu = Menu.SubMenu;

const buildOptionMenu = (item) =>
  item.path && item.show ? (
    <Menu.Item key={item.path} icon={item.icon && <Icon type={item.icon} />}>
      <Link to={item.path}>
        {/* {item.icon && <Icon type={item.icon} style={{ fontSize: '1.2rem' }} />} */}
        <span className="nav-text">{item.title}</span>
      </Link>
    </Menu.Item>
  ) : (
    item.component &&
    React.createElement(item.component(), { key: item.index }, null)
  );

const buildMenu = (menu, t) => {
  if (menu.subMenus) {
    return (
      <SubMenu
        key={menu.index}
        icon={menu.icon && <Icon type={menu.icon} />}
        title={<span className="nav-text">{menu.name}</span>}
      >
        {menu.subMenus.map((item) => buildOptionMenu(item))}
      </SubMenu>
    );
  }
  return buildOptionMenu(menu, t);
};

const MenuPrimary = ({ pathname, ...rest }) => {
  const menus = useMenu("primary");
  return (
    <Menu mode="inline" theme="dark" selectedKeys={[pathname || "/"]} {...rest}>
      {menus.map((option) => buildMenu(option, option.name))}
    </Menu>
  );
};

export default MenuPrimary;

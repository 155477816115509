import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation, Outlet } from "react-router-dom";
import { Layout } from "antd";

import { MenuPrimary, Header, Breadcrumb } from "../../components/Layout";
import { VERSION, ROUTES } from "../../config/constants";
import { useAuthenticated } from "../../components/Auth";

const { Content, Footer, Sider } = Layout;

const Container = ({ logout }) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const { isAuthenticated } = useAuthenticated();
  const navigate = useNavigate();
  const toggleCollapse = () => setCollapsed(!isCollapsed);
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated == false) {
      logout(() => navigate(ROUTES.signIn));
    }
  }, [isAuthenticated]);

  return (
    <Layout>
      <Sider
        id="logo-content"
        collapsible
        collapsed={isCollapsed}
        onCollapse={toggleCollapse}
      >
        <div className="logo" />
        <MenuPrimary pathname={location.pathname} />
      </Sider>
      <Layout style={{ minHeight: "100vh" }}>
        <Header style={{ background: "#fff", padding: 0 }} />
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb />
          <div style={{ padding: 24, minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Oasis back office © 2021
          <br />
          {VERSION}
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapDispatch = (dispatch) => ({
  logout: (callback) => dispatch.auth.logout({ onLogout: callback }),
});

const mapState = () => ({});

export default connect(mapState, mapDispatch)(Container);

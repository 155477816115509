import React from "react";
import {
  Input,
  InputNumber,
  Switch,
  DatePicker,
  Select,
  Checkbox,
  Row,
  AutoComplete,
  Cascader,
  Divider,
  Form,
} from "antd";
import { Label, Upload } from "../../components/Shared";
import ColorPicker from "../Shared/ColorPicker";

const { RangePicker } = DatePicker;

function renderOption(item) {
  return (
    <AutoComplete.Option key={item.key} text={item.value}>
      {item.value}
      <span className="global-search-item-count" />
    </AutoComplete.Option>
  );
}

const getForm = (field, form, fields, layout) => {
  const globalOptions = {
    initialValue: field.value || field.initialValue || undefined,
  };

  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option?.text?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  field.rules = (field.rules || []).map((rule) => ({
    ...rule,
    message: rule.message,
  }));

  switch (field.type) {
    case "date": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <DatePicker
            disabled={field.disabled || false}
            {...field?.features}
            showTime={field.showTime || false ? { format: "HH:mm" } : false}
            format={`YYYY-MM-DD ${field.showTime || false ? "HH:mm" : ""}`}
          ></DatePicker>
        </Form.Item>
      );
    }
    // case "time": {
    //   return getFieldDecorator(field.key, {
    //     ...globalOptions,
    //     rules: field.rules,
    //   })(
    //     <TimePicker
    //       disabled={field.disabled || false}
    //       format="HH:mm"
    //       {...field.config}
    //     />
    //   );
    // }
    case "rangedate": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <RangePicker
            disabled={field.disabled || false}
            {...field.features}
            showTime={field.showTime || false ? { format: "HH:mm" } : false}
            format={`YYYY-MM-DD ${field.showTime || false ? "HH:mm" : ""}`}
          />
        </Form.Item>
      );
    }
    case "number": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <InputNumber
            disabled={field.disabled || false}
            min={field.min || 0}
            formatter={
              field.money || false
                ? (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : null
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            {...field?.features}
          />
        </Form.Item>
      );
    }
    case "bool": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          onChange={(value) =>
            field.change
              ? field.change(value, field.key, form, fields)
              : undefined
          }
          valuePropName="checked"
          initialValue={
            field.hasOwnProperty("value")
              ? field.value
              : field.hasOwnProperty("initialValue")
              ? field.initialValue
              : false
          }
          {...layout}
          {...field?.formFeatures}
        >
          <Switch disabled={field.disabled || false} {...field?.features} />
        </Form.Item>
      );
    }
    case "select": {
      const filtrable = {
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input, option) =>
          (option?.children ?? "").includes(input),
        filterSort: (optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase()),
      };
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <Select
            disabled={field.disabled || false}
            mode={field.mode || "default"}
            onChange={(value) =>
              field.change
                ? field.change(value, field.key, form, fields)
                : undefined
            }
            {...(field?.features?.filtrable ? filtrable : {})}
            {...field?.features}
          >
            {Object.keys(field.options || {}).map((keyOption) => (
              <Select.Option key={keyOption} value={keyOption}>
                {field.options[keyOption]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
    // case 'radio': {
    //   return getFieldDecorator(field.key, {
    //     ...globalOptions,
    //     rules: field.rules,
    //   })(
    //     <Radio.Group disabled={field.disabled || false}>
    //       {Object.keys(field.options).map(keyOption => (
    //         <Radio key={keyOption} value={keyOption}>
    //           {field.options[keyOption]}
    //         </Radio>
    //       ))}
    //     </Radio.Group>,
    //   );
    // }
    case "checkbox": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...field?.formFeatures}
          {...layout}
        >
          <Checkbox.Group
            disabled={field.disabled || false}
            {...field?.features}
          >
            {Object.keys(field.options || {}).map((keyOption) => (
              <Row key={keyOption}>
                <Checkbox key={keyOption} value={keyOption}>
                  {field.options[keyOption]}
                </Checkbox>
              </Row>
            ))}
          </Checkbox.Group>
        </Form.Item>
      );
    }
    // case 'autocomplete': {
    //   const data = field.options ? Object.entries(field.options) : [];
    //   return getFieldDecorator(field.key, {
    //     ...globalOptions,
    //     rules: field.rules,
    //   })(
    //     <AutoComplete
    //       dataSource={data.map(([key, value]) => renderOption({ key, value }))}
    //       optionLabelProp="text"
    //       disabled={field.disabled || false}
    //       {...field.config}
    //       filterOption={(inputValue, option) =>
    //         option.props.children[0]
    //           .toUpperCase()
    //           .indexOf(inputValue.toUpperCase()) !== -1
    //       }>
    //       <Input />
    //     </AutoComplete>,
    //   );
    // }
    // case 'rich': {
    //   return getFieldDecorator(field.key, {
    //     ...globalOptions,
    //     rules: field.rules,
    //   })(
    //       <h1>Implement Component</h1>
    // //   <RichEditor />
    //   );
    // }
    case "textarea": {
      const { TextArea } = Input;
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <TextArea
            autosize={{
              minRows: field.minRow || 6,
              maxRows: field.maxRow || 12,
            }}
            disabled={field.disabled || false}
            {...field?.features}
          />
        </Form.Item>
      );
    }
    case "cascader": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <Cascader
            disabled={field.disabled || false}
            fieldNames={{ label: "text", value: "value", children: "items" }}
            options={field?.options?.data}
            showSearch={{ filter }}
            {...field?.features}
            showCheckedStrategy={"SHOW_CHILD"}
          />
        </Form.Item>
      );
    }
    case "label": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <Label {...field.config} {...field?.features} />
        </Form.Item>
      );
    }
    // case 'tag': {
    //   return getFieldDecorator(field.key, {
    //     ...globalOptions,
    //     // rules: field.rules,
    //   })(<CustomTag form={form} field={field} />);
    // }
    case "divider": {
      return (
        <Divider orientation={field.direction || "left"} {...field?.features}>
          {field.title}
        </Divider>
      );
    }
    case "file": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <Upload
            disabled={field.disabled || false}
            action={field.action}
            {...field?.features}
          />
        </Form.Item>
      );
    }
    case "color": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <ColorPicker
            disabled={field.disabled || false}
            {...field?.features}
            onChange={(value) =>
              field.change
                ? field.change(value, field.key, form, fields)
                : undefined
            }
          />
        </Form.Item>
      );
    }

    // case 'tree-select': {
    //   return getFieldDecorator(field.key, {
    //     ...globalOptions,
    //     rules: field.rules,
    //   })(<TreeSelect {...field} />);
    // }
    case "password": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <Input.Password
            disabled={field.disabled || false}
            {...field?.features}
          />
        </Form.Item>
      );
    }
    default:
    case "string": {
      return (
        <Form.Item
          {...globalOptions}
          label={field.title}
          key={field.key}
          name={field.key}
          rules={field.rules}
          {...layout}
          {...field?.formFeatures}
        >
          <Input disabled={field.disabled || false} {...field?.features} />
        </Form.Item>
      );
    }
  }
};

export default getForm;

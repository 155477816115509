import Instance from "./instance";

const URLs = {
  upload: "helper/upload",
  remove: "helper/removeFile",
};

export const uploadService = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return Instance.post(URLs.upload, formData);
};

export const removeService = (file) => Instance.delete(URLs.remove, file);

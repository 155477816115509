import React, { useState, useEffect } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LogoutOutlined,
  FileSyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SwitchChangeTheme } from "../Themes";

import { ChangePassword } from "../Auth";

const MenuDrop = ({ logout, changePassword }) => {
  return (
    <Menu>
      <Menu.Item icon={<FileSyncOutlined />} onClick={changePassword}>
        {"Cambiar Contraseña"}
      </Menu.Item>
      <Menu.Item icon={<SwitchChangeTheme />}>{"Dark Theme"}</Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
        {"Logout"}
      </Menu.Item>
    </Menu>
  );
};

const HeaderUser = ({ user = {}, logout }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const onLogout = () =>
    logout(() => {
      navigate("/login");
    });

  return (
    <>
      <Dropdown
        trigger={["click"]}
        overlay={() => (
          <MenuDrop logout={onLogout} changePassword={() => setVisible(true)} />
        )}
        placement="bottomRight"
      >
        <div className="custom-header-user">
          <Avatar icon={<UserOutlined />} />
          <span style={{ paddingLeft: 8 }}>{user?.fullname}</span>
        </div>
      </Dropdown>

      <ChangePassword visible={visible} onCancel={() => setVisible(false)} />
    </>
  );
};

HeaderUser.displayName = "HeaderUser";

export default connect(
  (state) => ({ user: state.auth }),
  (dispatch) => ({
    logout: (onLogout) => dispatch.auth.logout({ onLogout }),
  })
)(HeaderUser);

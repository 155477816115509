import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slider as SliderAnt } from 'antd';

const Slider = forwardRef(
  (
    {
      tooltipVisible = true,
      options,
      markStyle = {
        color: '#f50',
      },
      subTitleText,
      step = null,
      range = false,
      value,
      onChange,
    },
    ref,
  ) => {
    const marks = {},
      [val, setVal] = useState(value || (range ? [0, 0] : 0));

    useEffect(() => {
      onChange(val);
    }, [val, onChange, value]);

    options &&
      Object.keys(options).map((e, i) => {
        const name = options[e];
        marks[i] = {
          key: e,
          markStyle,
          label: (
            <div>
              <h5>{name}</h5>
              {subTitleText ? <small>{`${subTitleText} ${i + 1}`}</small> : ''}
            </div>
          ),
        };
      });

    return (
      <SliderAnt
        ref={ref}
        range={range}
        tooltipVisible={tooltipVisible}
        {...(options
          ? {
              marks,
              max: Object.keys(options).length - 1,
            }
          : {})}
        step={step}
        velue={val}
        defaultValue={val}
        onAfterChange={v => setVal(v)}
      />
    );
  },
);

Slider.prototype = {
  step: PropTypes.number,
  range: PropTypes.bool,
  value: PropTypes.array,
  options: PropTypes.object,
  markStyle: PropTypes.object,
  subTitleText: PropTypes.string,
  tooltipVisible: PropTypes.bool,
};

export default Slider;
import instance from "./instance";

const urls = {
  getById: "parameters",
  find: "parameters/find",
  save: "parameters",
};

export const saveService = (data) => instance.put(urls.save, data);

export const getByIdService = (id) =>
  instance.get(`${urls.getById}/${id}`, { params: { id } });

export const findService = (data) =>
  instance.get(urls.find, {
    params: {
      data,
    },
  });

import React from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";

import { breadcrumbNameMap } from "../../config/constants";

const BreadcrumbLayout = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (typeof breadcrumbNameMap[url] === "object") {
      const pre = breadcrumbNameMap[url][1];
      return (
        <>
          <Breadcrumb.Item key={pre[0]}>
            <Link to={pre[0]}>{pre[1]}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key={url}>
            <Link to={url}>{breadcrumbNameMap[url][0]}</Link>
          </Breadcrumb.Item>
        </>
      );
    }

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url] || "Editar"}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb style={{ margin: "16px 0" }}>{breadcrumbItems}</Breadcrumb>
  );
};

export default BreadcrumbLayout;

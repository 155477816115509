const defautlState = {};

const user = {
	state: defautlState,
	reducers: {
		SET_USER: (state, payload) => ({
			...payload
		}),
	},
	effects: {
		selectUser({ userData, callback }) {
			this.SET_USER(userData);
			callback && callback();
		},
		resetUser({ callback }) {
			this.SET_USER(defautlState);
			callback && callback();
		},
	}
};

export default user;
import { list } from "../services/rpc";
import { STATUS_CODE } from "../config/constants";

const defaultState = {
  list: [],
  current: null,
};

const rpc = {
  state: defaultState,
  reducers: {
    SET_LIST: (state, payload) => ({
      ...state,
      ...payload,
    }),
    SET_CURRENT: (state, payload) => ({
      ...state,
      current: payload.current,
    }),
  },
  effects: {
    setCurrent({ rpc, callback }) {
      this.SET_CURRENT({ current: rpc });
      callback && callback();
    },
    async getAll({ callback }) {
      try {
        const response = await list();
        const resData = response.data;
        if (resData.statusCode !== STATUS_CODE.SUCCESS) {
          Promise.reject(resData.message);
        }
        this.SET_LIST({
          list: resData.data,
        });
        callback && callback();
      } catch (error) {
        this.SET_LIST(defaultState);
      }
    },
  },
};

export default rpc;

import { dateToString } from '../../utils/general';
const config = {
  keyName: 'id',
  getList: `/userChargerLog/report`,
  getByKey: '/userChargerLog',
  message: 'message',
  fields: [
    {
      title: 'Usuario',
      key: 'username',
      textKey: 'username',
      sorter: true,
      filter: true,
    },
    {
      title: 'Nombre Completo',
      key: 'fullname',
      textKey: 'fullname',
      sorter: true,
      filter: true,
    },
    {
      title: 'Celular',
      key: 'cellphone',
      textKey: 'cellphone',
      sorter: true,
      filter: true,
    },
    {
      title: 'E-mail',
      key: 'email',
      textKey: 'email',
      sorter: true,
      filter: true,
    },
    {
      title: 'Dirección de residencia',
      key: 'address',
      textKey: 'address',
      sorter: true,
      filter: true,
    },
    {
      title: 'Fecha de primera carga',
      key: 'inicioCarga',
      textKey: 'inicioCarga',
      sorter: true,
      filter: true,
      render: (text, record) =>
        dateToString(record.inicioCarga)
    },
    {
      title: 'Fecha de última carga',
      key: 'finCarga',
      textKey: 'finCarga',
      sorter: true,
      filter: true,
      render: (text, record) =>
        dateToString(record.finCarga)
    },
    {
      title: 'Cargas Realizadas',
      key: 'sesiones',
      textKey: 'sesiones',
      sorter: true,
      filter: true,
    },
    {
      title: 'Tiempo total de carga',
      key: 'tiempo',
      textKey: 'tiempo',
      sorter: true,
      filter: true,
      render: (text) => `${text} minutos`
    },
  ],
};

export default config;
import React from 'react';
import { connect } from "react-redux";
import PropTypes, { string } from "prop-types";

import AccessDenied from "./AccessDenied";

const Authorization = ({ children, permissionsList, action, screen }) => {
  if (permissionsList == null) {
    return <></>;
  }

  const userHasRequiredRole = permissionsList[screen]
    ? permissionsList[screen].includes(action)
    : false;

  if (!userHasRequiredRole) {
    return <AccessDenied />;
  }

  return children;
};

const mapState = (state) => ({
  permissionsList: state.auth.permissions,
});
const mapDispatch = (dispatch) => ({});

Authorization.propTypes = {
  children: PropTypes.node,
  action: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  screen: PropTypes.string.isRequired,
};

export default connect(mapState, mapDispatch)(Authorization);

import { list } from "../services/rpcStatus";
import { STATUS_CODE } from "../config/constants";

const defaultState = {
  list: [],
  totalCount: null,
  currentPage: 1,
};

const rpcStatus = {
  state: defaultState,
  reducers: {
    SET_STATE: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: {
    async getData({ rpcId, limit, offset, current, callback }) {
      try {
        const response = await list(rpcId, limit, offset);
        const resData = response.data;
        if (resData.statusCode !== STATUS_CODE.SUCCESS) {
          Promise.reject(resData.message);
        }
        const { data } = resData;
        this.SET_STATE({
          list: data.list,
          totalCount: data.totalCount,
          currentPage: current,
        });
        callback && callback();
      } catch (error) {
        this.SET_STATE(defaultState);
      }
    },
  },
};

export default rpcStatus;

import React from "react";
import { FiWifiOff } from "react-icons/fi";
import { FaChargingStation } from "react-icons/fa";
import { TbGasStationOff } from "react-icons/tb";
import { MdCampaign } from "react-icons/md";

import { Card, Col, Row, Statistic } from "antd";

import { useFetchList, Title } from "../Shared";
import { getActiveChargersService } from "../../services/charger";
import { getAllActiveService } from "../../services/publicityCampaing";
import { findService } from "../../services/rpc";
import { findService as findStationService } from "../../services/station";
import { useChargerSocket } from "../../components/Charger/hooks";
import { useEffect } from "react";

const InfoCard = () => {
  const activeChargerApi = useFetchList();
  const activeCampaingsApi = useFetchList();
  const rpcApi = useFetchList();
  const stationApi = useFetchList();

  const socketHandler = (data) => {
    switch (data.status) {
      case "ON":
      case "OFF_ADMIN":
      case "OFF":
      case "RPC_OFF":
        activeChargerApi.load(getActiveChargersService);
        break;
    }
  };

  useChargerSocket({
    observer: socketHandler,
  });

  useEffect(() => {
    activeChargerApi.load(getActiveChargersService);
    activeCampaingsApi.load(getAllActiveService);
    rpcApi.load(findService, () => {}, {
      online: 0,
    });
    stationApi.load(findStationService, () => {}, {
      enabled: 1,
    });
  }, []);

  return (
    <Row gutter={[16, 16]} style={{ margin: "15px 0px" }}>
      <Col span={8}>
        <Card hoverable>
          <Statistic
            title="Cargadores Activos"
            value={activeChargerApi.list?.length}
            valueStyle={{ color: "#3f8600" }}
            prefix={<FaChargingStation />}
            suffix={<Title level={5} text={"Cargadores"} />}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card hoverable>
          <Statistic
            title="Campañas de publicidad activas"
            value={activeCampaingsApi.list?.length}
            valueStyle={{ color: "#3f8600" }}
            prefix={<MdCampaign />}
            suffix={<Title level={5} text={"Campañas"} />}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card hoverable>
          <Statistic
            title="Estaciones en Mantenimiento"
            value={stationApi.list?.length}
            valueStyle={{ color: "#cf1322" }}
            prefix={<TbGasStationOff />}
            suffix={<Title level={5} text={"Estaciones"} />}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card hoverable>
          <Statistic
            title="RPC's Desconectadas"
            value={rpcApi.list?.length}
            valueStyle={{ color: "#cf1322" }}
            prefix={<FiWifiOff />}
            suffix={<Title level={5} text={"Offline"} />}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default InfoCard;

import Instance from "./instance";

const url = {
    get: 'rpc',
    getLog: 'rpc/actionsLog',
    duplicate: 'rpc/duplicate',
    find: 'rpc/find',
}

export const list = () => Instance.get(url.get);

export const findService = (data) => Instance.get(url.find, {
    params: data
});

export const duplicateService = (id) => Instance.post(`${url.duplicate}/${id}`, {id});

export const getActionsLogService = (id) => Instance.get(`${url.getLog}/${id}`);
    
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/integration/react";
import { Spin } from "antd";

import store from "./config/store";
import Router from "./config/routes";
import * as registerServiceWorker from "./registerServiceWorker";
import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./components/Themes/theme-provider";
import { SocketProvider } from "./context";

const persistor = getPersistor();

const AppSuspense = () => (
  <Suspense fallback={<Spin size="large" className="custom-layout-spin" />}>
    <App />
  </Suspense>
);

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider>
          <SocketProvider>
            <Router />
          </SocketProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <AppSuspense />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const defautlState = {
	menues: []
};

const role = {
	state: defautlState,
	reducers: {		
		SET_MENUES: (state, payload) => ({
			...state,
			menues: payload
		}),
	},
	effects: {
		selectMenues({menues, callback}) {						
			this.SET_MENUES(menues);
			callback && setTimeout(() => {
				callback();
			}, 500); 
		},
	}
};

export default role;
import { getByIdService, saveService } from '../services/settings';
import { STATUS_CODE } from '../config/constants';

const defaultState = {
    list: []
};

const settings = {
    state: defaultState,
    reducers: {
        SET_LIST: (state, payload) => ({
            ...state,
            ...payload
        })
    },
    effects: {
        async getById({id}) {
            try {
                const response = await getByIdService(id);
                const resData = response.data;
                if (resData.statusCode !== STATUS_CODE.SUCCESS) {
                    Promise.reject(resData.message);
                }
                this.SET_LIST({
                    list: resData.data
                });
            } catch (error) {
                this.SET_LIST(defaultState);
            }
        },
        async save({data, callback}) {
            try {
                const response = await saveService(data);
                const resData = response.data;
                if (resData.statusCode !== STATUS_CODE.SUCCESS) {
                    Promise.reject(resData.message);
                }
                this.SET_LIST(defaultState);
                callback && callback();
            } catch (error) {
                Promise.reject(error);
            }
        },
        cancel({callback}) {
            callback && callback();
            this.SET_LIST(defaultState);
        }
    }
};

export default settings;
import { getAllService } from '../services/station';
import { STATUS_CODE } from '../config/constants';

const defaultState = {
    list: []
};

const station = {
    state: defaultState,
    reducers: {
        SET_LIST: (state, payload) => ({
            ...state,
            ...payload
        })
    },
    effects: {
        async getAll({ callback }) {
            try {
                const response = await getAllService();
                const resData = response.data;
                if (resData.statusCode !== STATUS_CODE.SUCCESS) {
                    Promise.reject(resData.message);
                }
                this.SET_LIST({
                    list: resData.data
                });
                callback && callback();
            } catch (error) {
                this.SET_LIST(defaultState);
            }
        }
    }
};

export default station;
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { useAuthenticated } from "./";
import { ROUTES } from "../../config/constants";
import AccessDenied from "./AccessDenied";

const AuthRoute = ({ children, permissionsList, user, permission }) => {
  let location = useLocation();
  const { isAuthenticated } = useAuthenticated();
  if (permissionsList == null) {
    return <AccessDenied />;
  }

  if (permissionsList == null) {
    return <AccessDenied />;
  }
  
  const userHasRequiredRole =
    user && Object.keys(permissionsList).includes(permission) ? true : false;

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.signIn} state={{ from: location }} />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />;
  }
  
  return children;
};

export default connect(
  (state) => ({
    user: state.auth.user,
    permissionsList: state.auth.permissions,
  }),
  (dispatch) => ({})
)(AuthRoute);
